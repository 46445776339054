import paper from 'paper/dist/paper-core';

const {
    Path,
    Point,
    Layer,
    Size
} = paper;

const largeCount = 4;
const smallCount = 16;
const speed = 400;
let scope;

const init = function(canvas){
    scope = new paper.PaperScope();
    scope.setup(canvas);

    const path = new Path();

    path.fillColor = '#00257A';
    path.add(new Point(0, 0));
    path.add(new Point(50, 0));
    path.add(new Point(50, 50));
    path.add(new Point(100, 50));
    path.add(new Point(100, 100));
    path.add(new Point(50, 100));
    path.add(new Point(50, 150));
    path.add(new Point(0, 150));
    path.add(new Point(0, 100));
    path.add(new Point(-50, 100));
    path.add(new Point(-50, 50));
    path.add(new Point(0, 50));
    path.add(new Point(0, 0));

    var size = new Size(80, 80);
    const symbol = new scope.Symbol(path, size);
    

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function addSymbols(count, size, opacity){
        new Layer();

        // Place the instances of the symbol:
        for (var i = 0; i < count; i++) {
            // The center position is a random point in the view:
            const center = scope.view.size.multiply(Point.random());
            const placedSymbol = symbol.place(center);
            const rand =  getRandomArbitrary(size.min, size.max);
            placedSymbol.scale( (scope.view.size.height / 960) * rand);
            placedSymbol.opacity = opacity || rand;
            placedSymbol.angle = getRandomArbitrary(0, 360);
        }
    }

    // const size = 80 / scope.view.size.width;
    addSymbols( smallCount, { min: 0.1, max: 0.25  } );
    addSymbols( largeCount, { min: 0.4, max: .5 }, 1 );

    // // The onFrame function is called up to 60 times a second:
    function onFrame(event) {
        for (let index = 0; index < scope.project.layers.length; index++) {
            const layer = scope.project.layers[index];
            
            for (var i = 0; i < layer.children.length; i++) {
                const item = layer.children[i];

                // larger circles move faster than smaller circles:
                item.position.x += (item.bounds.width / speed) * Math.cos(item.angle);
                item.position.y += (item.bounds.height / speed) * Math.sin(item.angle);
    
                // If the item has left the view on the right, move it back
                // to the left:
                if (item.position.x > scope.view.size.width + item.bounds.width) {
                    item.position.x = -item.bounds.width;
                }

                if (item.position.x < -item.bounds.width) {
                    item.position.x = scope.view.size.width + item.bounds.width;
                }

                if (item.position.y > scope.view.size.height + item.bounds.height) {
                    item.position.y = -item.bounds.height;
                }
               
                if (item.position.y < -item.bounds.height) {
                    item.position.y = scope.view.size.height;
                }
            }
        }
        
    }

    scope.view.onFrame = onFrame;
    scope.view.draw();
}

const destroy = () => {
    scope.remove();
    scope.clear();
    scope = null;
}

export default { init, destroy };