import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import Button, { BUTTON_THEMES } from '../Button/Button';
import AppContext from "../../context/AppContext";
import useOnScreen from '../../hooks/useOnScreen';
import { Modal } from '../Modal/Modal';
import Contact from '../Contact/Contact';
import logo from '../../assets/rhm-logo-strapless.svg';
import logoWhite from '../../assets/rhm-logo-strapless-white.svg';
import './Header.scss';

function Header({ hideLogo = false }) {
    const ref = useRef( null );
    const [isLogoVisible, setLogoVisible] = useState( !hideLogo );
    const [showDialog, setShowDialog] = useState(false);
    const { state } = useContext( AppContext );
    const isThemePrimary = state.theme === 'primary';
    const isVisible = useOnScreen(ref, '200px');

    function handleBtnClick(){
        setShowDialog( true );
    }

    useEffect(() => {
        if( hideLogo ) {
            setLogoVisible( !isVisible );
        }
    }, [isVisible, hideLogo])

    return (
        <header className="Header" ref={ref}>
            <Link className="Header__brand" to="/">
                <img className={['Header__brand-img', isLogoVisible && !isThemePrimary ? 'is-active' : ''].join(' ')} src={logo} alt="RHM"/>
                <img className={['Header__brand-img', isLogoVisible && isThemePrimary ? 'is-active' : ''].join(' ')} src={logoWhite} alt="RHM"/>
            </Link>

            <div className="Header__btn">
                <Button onClick={handleBtnClick} theme={isThemePrimary ? BUTTON_THEMES.white : BUTTON_THEMES.default } id="contact">
                    Get in touch
                </Button>
                <Modal isOpen={showDialog} onClose={e => setShowDialog(false)} labelledBy="contact">
                    <Contact/>
                </Modal>
            </div>
        </header>
    );
}

export default Header;