import React from 'react';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import './Modal.scss';
import Icon from '../Icon/Icon';

export const Modal = ({children, onClose, labelledBy, ...other}) => {
  return (
    <DialogOverlay className="Modal" {...other}>
      <DialogContent className="Modal__content" aria-labelledby={labelledBy}>
        <button className="Modal__close" onClick={onClose} aria-label="Close">
          <Icon name="close"/>
        </button>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}