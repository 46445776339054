import React from 'react';
import './Button.scss';

export const BUTTON_THEMES = {
    default: 'Button--default',
    white: 'Button--white'
};

function Button({ children, onClick, theme }) {
    return (
        <button className={['Button', theme].join(' ')} onClick={onClick}>
            {children}
        </button>
    );
}

export default Button;