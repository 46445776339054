import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Icon from '../Icon/Icon';
import './SocialLinks.scss';

const query = graphql`
  query Social {
    site {
      siteMetadata {
        socialUsernames {
          linkedin
          instagram
          facebook
          twitter
        }
      }
    }
  }
`

const SocialLinkItem = ({ url, icon }) => (
  <li className="SocialLinkItem">
    <a className="SocialLinkItem__link" href={url} target="_blank" rel="noreferrer">
      <Icon className="SocialLinkItem__icon" name={icon}/>
    </a>
  </li>
)

export const SocialLinks = () => {
  const { site: { siteMetadata: { socialUsernames } } } = useStaticQuery(query);

  return (
    <ul className="SocialLinks">
      { socialUsernames.linkedin &&
        <SocialLinkItem url={`https://www.linkedin.com/in/${socialUsernames.linkedin}/`} icon="linkedin"/>
      }

      { socialUsernames.instagram &&
        <SocialLinkItem url={`https://www.instagram.com/${socialUsernames.instagram}/`} icon="instagram"/>
      }
  
      { socialUsernames.facebook &&
        <SocialLinkItem url={`https://www.facebook.com/${socialUsernames.facebook}/`} icon="facebook"/>
      }

      { socialUsernames.twitter &&
        <SocialLinkItem url={`https://www.twitter.com/${socialUsernames.twitter}/`} icon="twitter"/>
      }
    </ul>
  )
}