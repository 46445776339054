import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Footer from "../Footer/Footer";
import SEO from "../Seo";
import AppContext from "../../context/AppContext";
import './Layout.scss';
import Header from "../Header/Header";
import Background from "../Background/Background";

export default function Layout({ children, hideLogo, theme, ...seo }) {
  const { state } = useContext( AppContext );
  const [currentTheme, setTheme] = useState( theme );

  useEffect( () => {
    setTheme( state.theme );
  }, [state.theme]);

  return (
    <>
      <SEO {...seo}/>

      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap" rel="stylesheet"/>
      </Helmet>

      <Header hideLogo={hideLogo}/>
      <main className={['Main', currentTheme].join(' ')}>
        {children}
      </main>
      <Footer/>
      <Background theme={currentTheme}/>
    </>
  )
}