import React from 'react';
import starfield from './starfield';
import './Background.scss';

function Background({ theme }) {
    const ref = React.useRef( null );

    React.useLayoutEffect(() => {
        starfield.init( ref.current );

        return () => {
            starfield.destroy();
        }
    },[])

    return (
        <div className={['Background', theme].join(' ')}>
            <div className="Background__overlay-primary"/>
            <div className="Background__overlay-secondary"/>
            <canvas className="Background__canvas" ref={ref} data-paper-resize="true" data-paper-hidpi="off"/>
        </div>
    );
}

export default Background;