import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { SocialLinks } from '../SocialLinks/SocialLinks';
import './Footer.scss';

const query = graphql`
  query Footer {
    navigation: prismicNavigation {
      data {
        menu_items {
          menu_item {
            uid
          }
          label {
            text
          }
        }
      }
    }
  }
`

function Footer() {
    const { navigation } = useStaticQuery(query);

    return (
      <footer className="Footer">
        <SocialLinks/>

        <div className="Footer__body">
          <ul className="Footer__nav">
            {navigation.data.menu_items.map(({menu_item, label }) => (
              <li className="Footer__nav-item" key={menu_item.uid}>
                <Link className="Footer__nav-link" to={`/${menu_item.uid}`}>{label.text}</Link>
              </li>
            ))}
          </ul>
          <p className="Footer__copy">
            &copy;{new Date().getFullYear() } Rankine Healthcare Marketing Ltd. All Rights Reserved.
          </p>
        </div>
      </footer>
    );
}

export default Footer;