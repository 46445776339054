import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { SocialLinks } from '../SocialLinks/SocialLinks';
import './Contact.scss';

const query = graphql`
  query Contact {
    page: prismicContact {
      data {
        heading {
          text
        }
        lead {
          text
        }
        tel {
          text
        }
        email {
          text
        }
      }
    }
  }
`
function Contact() {
  const { page: { data } } = useStaticQuery(query);

  return (
    <div className="Contact">
      <h2 className="Contact__heading">{data.heading.text}</h2>
      <p className="Contact__intro">{data.lead.text}</p>

      <ul className="Contact__methods">
        <li className="Contact__method">
          <h3 className="Contact__method-name">Telephone</h3>
          <p className="Contact__method-value"><a href={`tel:+${data.tel?.text.replace(/ /g, '')}`}>{data.tel.text}</a></p>
        </li>
        <li className="Contact__method">
          <h3 className="Contact__method-name">Email</h3>
          <p className="Contact__method-value"><a href={`mailto:${data.email.text}`}>{data.email.text}</a></p>
        </li>
        <li className="Contact__method">
          <h3 className="Contact__method-name">Social</h3>
          <SocialLinks />
        </li>
      </ul>
    </div>
  );
}

export default Contact;